/*************************************************
* NAVIGATION
*************************************************/

/* 
nav - base styles 
************************/
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  @include laptop {
    padding: 2rem 0;
  }

  /* 
  nav - logo
  ************************/

  &__logo {
    text-decoration: none;
    color: $medium-grey;

    &--last-name {
      color: $dark-grey;
      font-weight: 700;
    }

    &:hover {
      color: $dark-grey;
      font-weight: 700;
      transition: color 300ms;

      & .nav__logo--last-name {
        color: $medium-grey;
        font-weight: 400;
        transition: color 300ms;
      }
    }

    @include tablet {
      font-size: rem(20, 16); /* 20px / 16px */
    }

    @include desktop {
      font-size: rem(28, 16); /* 28px / 16px */
    }

  }

  /* 
  nav - logo
  ************************/
  &__menu__overlay {
    background-color: $white;
    background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("/media/img/alejandro-loguercio/alejandro-loguercio-violin-mobile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity $trans-fast, z-index $trans-fast;
    z-index: -1;
    overflow: hidden;

    @include tablet {
      background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("/media/img/alejandro-loguercio/alejandro-loguercio-violin.jpeg");
    }


    @include laptop {
      all: unset;
    }

  }

}
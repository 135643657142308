/*************************************************
* GLOBAL
*************************************************/

/* 
Root element 
************************/
html {
  font-size: 100%;
  line-height: 1.6875;
}

/* 
Max-width for images
************************/
img {
  max-width: 100%;
  height: auto;
}

/* 
Box sizing property
************************/
* {
  box-sizing: border-box;
}

/* 
Paragraph reset
************************/
p {
  margin: 0;
  padding: 0;
}

/* 
Default color for all links
************************/
a {
  color: $dark-grey;
}

/* 
Prevent body from scrolling when main nav is opened
************************/
.no__scroll {
  overflow: hidden;
  position:fixed;
}

/* 
Line (for decoration purposes)
************************/
.ruler {
  & > .line {
    display: inline-block;
    height: rem(3,16); /* 3px / 16px */
    width: rem(50,16); /* 50px / 16px */
    background-color: $blue;
  }

  &>.square {
    display: inline-block;
    height: rem(3,16); /* 3px / 16px */
    width: rem(3,16); /* 3px / 16px */
    background-color: $blue;
  }
}

/* 
Clearfix
************************/
.clearfix {
  content: '';
  clear: both;
  display: table;
}
/* 
Hidden / Visible classes
************************/
// .element {
//   &__hidden {
//     opacity: 0;
//   }
//   &__visible {
//     opacity: 1;
//   }
// }
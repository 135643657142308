/************************************************************************** 
/ GOOGLE FONTS
**************************************************************************/
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700');

// Base styles
@import 'base/variables';
@import 'base/mixins';
@import 'base/global';
@import 'base/layout';
@import 'base/typography';

// Components
@import 'components/navigation';
@import 'components/lists';
@import 'components/footer';
@import 'components/links';
@import 'components/btns';
@import 'components/imgs';
@import 'components/audio';
@import 'components/forms';
@import 'components/transitions';

// Pages
@import 'pages/homepage';
@import 'pages/subscribe';
.subscribe {
  margin-bottom: 4rem;

  @include laptop {
    margin-bottom: 0;
  }

  @include laptop {
    display: flex;
    flex-direction: row;
  }

  &__img {
    background-color: $light-grey;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 30vh;
    width: 100%;
    margin-bottom: rem(20, 16);
    flex: 1;
    flex-grow: 2;

    &--main {
      @include img("/media/img/crosmopolitans/crossmopolitans-subscribe-mobile-3.jpg");
      @include landscape {
        height: 60vh;
      }

      @include laptop {
        @include img("/media/img/crosmopolitans/crossmopolitans-subscribe-low.jpg");
      }
    }

    &--alternative {
      @include img("/media/img/crosmopolitans/crosmopolitans.jpg");
    }

    @include tablet {
      height: 60vh;
      background-position: top;
    }

    @include laptop {
      height: 100vh;
      max-height: 100vh;
      width: 100%;
      margin-bottom: 0;
    }

  }



  &__info {
    @include laptop {
      flex: 1;
      & .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        max-height: 100vh;
      }

      & .margin__b-extra-small {
        margin-bottom: rem(16, 16);
      }
    }

  }
}
//////////////////////
// Functions
//////////////////////

// Calcula rem
/////////////////
@function rem($fontSize, $fontBase) {
  $rem : ($fontSize/$fontBase) * 1rem;
  @return $rem;
}

//////////////////////
// Mixins
//////////////////////

// Media queries
/////////////////
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin laptop {
  @media (min-width: #{$laptop}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

// Images
/////////////////
@mixin img($url) {
  background-image: url($url);
}
// Image gradient URL
/////////////////
@mixin imgGradUrl($url) {
  background-image: linear-gradient(rgba(17, 22, 30, 0.5), rgba(17, 22, 30, 0.5)), url($url);
}
.form {
  &__label {
    display: block;
  }
  &__input {
    background-color: $light-grey;
    border: none;
    border: 2px solid $light-grey;
    border-radius: 4px;
    padding: 1em;
    width: 100%;
    &:hover,
    &:active
    &:focus {
      outline: none;
      border: 2px dotted $blue;
    }
    @include tablet {
      font-size: rem(16,16);
    }
  }

  &__textarea {
    height: 20rem; 
  }
}

.error {
  color: $error;
  margin-top: 0.5em !important;
  margin-bottom: 1em !important;
}
.section {

  &__title,
  &__subtitle {
    font-family: $serif;
    font-size: rem(12, 16);
    /* 12px / 16px */
    text-transform: uppercase;
  }

  &__title {
    color: $blue;
    font-weight: 700;
    position: relative;

    @include tablet {
      font-size: rem(16, 16);
      /* 16px / 16px */
    }
    @include desktop {
      font-size: rem(20,16); /* 20px / 16px */
    }

    &-number {
      position: absolute;
      font-size: 3rem;
      z-index: -1;
      color: $light-grey;
      top: -(rem(30,16)); /* 30px / 16px */
    }

    &--alternative {
      color: $white;
      font-weight: 300;
    }
  }

  &__subtitle {
    color: $medium-grey;
    font-weight: 400;
    margin-bottom: rem(0,16); /* 0px / 16px */

    @include tablet {
      font-size: rem(13, 16);
      /* 13px / 16px */
    }
    @include desktop {
      font-size: rem(16,16); /* 16px / 16px */
    }
  }

  &__paragraph {
    color: $dark-grey;
    font-family: $sans-serif;
    font-size: rem(16, 16);
    /* 16px / 16px */
    
    &__end {
      background-color: #2266A6;
      display: inline-block;
      height: rem(12,16); /* 12px / 16px */
      margin-left: 5px;
      width: rem(12,16); /* 12px / 16px */
    }

    @include tablet {
      font-size: rem(20, 16);
      /* 20px / 16px */
      font-weight: 300;
      line-height: 1.85;
    }
    @include desktop {
      font-size: rem(24,16); /* 24px / 16px */
    }

    &--big {
      @include laptop {
        font-size: rem(24,16); /* 24px / 16px */
      }
      @include desktop {
        font-size: rem(28,16); /* 24px / 16px */
      }
    }
    &--small {
      @include laptop {
        font-size: rem(16,16); /* 16px / 16px */
      }
    }

    &--alternative {
      color: $white;
    }

    &--cursive {
      font-style: italic;
    }
  }

  &__small {
    color: $medium-grey;

    @include desktop {
      font-size: 1rem;
    }
  }

}
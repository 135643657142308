.section {
  &__image {
    display: block;

    @include tablet {
      margin-left: auto;
      margin-right: auto;
    }

    &--project {
      height: rem(250, 16);
      /* 250px / 16px */
      width: 100%;
      object-fit: cover;
      object-position: top;

      @include tablet {
        height: rem(350, 16);
        /* 350px / 16px */
      }

      &--centered {
        object-position: center;
      }
    }

    &--banner {
      background-color: $light-grey;
      background-size: cover;
      background-position: center;
      height: rem(500, 16);

      &--float {
        height: rem(350,16);
      }

      &--bio {
        @include img("/media/img/alejandro-loguercio/alejandro-loguercio-white-shirt.jpeg"); 
      }
      &--crosmopolitans {
        @include img("/media/img/crosmopolitans/crosmopolitans-music.jpg");
      }
      &--acrosTrio {
        @include img("/media/img/acros-trio/acros-trio-red.jpg");
        background-position: top;
      }
      &--garufa {
        @include img("/media/img/garufa/garufa-members-holding-instruments.jpeg");
      }
      &--maxSteiner {
        @include img("/media/img/max-steiner/max_steiner_2.jpg");
      }
      &--collaboration {
        @include img("/media/img/collaboration/collaboration-artists.jpg");
      }
      &--contact {
        @include img("/media/img/alejandro-loguercio/alejandro-loguercio-with-violin.jpeg");
      }
  
      /* 500px / 16px */
      @include tablet {
        background-position: top;
        height: rem(550, 16);
        /* 550px / 16px */
        &--bio {
          @include img("/media/img/alejandro-loguercio/alejandro-loguercio-smiling-camera.jpeg"); 
        }
        &--contact {
          @include img("/media/img/alejandro-loguercio/alejandro-loguercio-violin-forehand-smiling.jpeg");
        }
        &--float {
          float: left;
          height: rem(450,16);
          width: 40%;
          margin-right: rem(32,16);
        }
      }
    }
  }
}

.press {
  &__image {
    display: none;

    @include tablet {
      display: block;
      background-image: url(/media/img/alejandro-loguercio/alejandro-loguercio-sitting-smiling.jpeg);
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      height: rem(355, 16);
      /* 355px / 16px */
      width: 100%;
    }

    @include laptop {
      background-position: top;
      height: rem(650, 16);
      /* 650px / 16px */
    }
  }
}

.audio__img {
  height: rem(250, 16);
  /* 250px / 16px */
  background-color: $medium-grey;
  background-image: url(/media/img/alejandro-loguercio/alejandro-loguercio-violin-forehand-smiling.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: -11%;
  margin-right: -11%;
  width: 100vw;

  @include tablet {
    height: rem(350, 16);
    /* 350px / 16px */
    margin: auto;
    width: auto;
  }

  @include laptop {
    background-position: center;
    float: left;
    height: rem(400, 16);
    /* 400px / 16px */
    width: rem(400, 16);
    /* 400px / 16px */
    margin-right: rem(32, 16);
    /* 32px / 16px */

    &::after {
      content: "";
      clear: both;
      display: table;

    }
  }

  @include desktop {
    height: rem(550, 16);
    /* 550px / 16px */
    width: rem(550, 16);
    /* 550px / 16px */
  }
}
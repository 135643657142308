/*************************************************
* LAYOUT
*************************************************/

/* 
Layout 
************************/
.container {
  width: 82%;
  margin: 0 auto;

  @include tablet {
    &--outer {
      width: 90%;
      max-width: 90%;
      margin: 0 auto;
    }

    &--inner {
      max-width: rem(700, 16);
    }
  }

  @include laptop {
    &--outer {
      max-width: 73.75rem;
    }

    &--inner {
      max-width: rem(850, 16);
    }
  }
}

.section {
  &--overflow {
    overflow: hidden;
  }

  &__header {
    background-color: $light-grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: rem(100, 16);

    /* 100px / 16px */
    @include desktop {
      height: rem(125, 16);
    }
  }

  /* Line divider (decoration purposes) */
  &__divider {
    background-color: $light-grey;
    height: rem(1, 16);
    /* 1px / 16px */
    width: 100%;
  }

  /* Multi-column layout */
  &__column {
    @include tablet {
      -webkit-columns: 1;
      columns: 1;
      // -webkit-column-gap: 2em;
      // column-gap: 2em;
    }

    @include laptop {
      -webkit-columns: 2;
      columns: 2;
      -webkit-column-gap: 2em;
      column-gap: 2em;
    }

  }

  /* Flex container */
  @include tablet {
    &--flex {
      display: flex;
      margin-left: auto;
      margin-right: auto;

      &>.section__divider {
        display: none;
      }

      &>.project {
        width: 50%;

        &:first-child {
          margin-right: rem(25, 16);
          /* 25px / 16px */
        }
      }
    }
  }

  &__iframe {
    display: block;
    margin: 0 auto;
    height: rem(300, 16);
    /* 300px / 16px */
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    @include tablet {
      height: rem(500, 16);
      /* 500px / 16px */
    }
  }

  &__logo {
    height: rem(32,16);
    width: rem(32,16);
  }

}

/* 
Margins 
************************/
.margin {
  &__r-reset {
    @include tablet {
      margin-right: 0;
    }
  }

  &__l-reset {
    @include tablet {
      margin-left: 0 !important;
    }
  }

  &__b-extra-small {
    margin-bottom: rem(12, 16);

    /* 12px / 16px*/
    @include laptop {
      margin-bottom: rem(20, 16);
    }
  }

  &__b-small {
    margin-bottom: rem(20, 16);

    /* 20px / 16px*/
    @include laptop {
      margin-bottom: rem(32, 16);
    }
  }

  &__b-normal {
    margin-bottom: rem(32, 16);

    /* 32px / 16px*/
    @include laptop {
      margin-bottom: rem(48, 16);
    }
  }

  &__b-large {
    margin-bottom: rem(48, 16);

    /* 48px / 16px*/
    @include laptop {
      margin-bottom: rem(96, 16);
      /* 96px / 16px */
    }
  }

  &__b-extra-large {
    margin-bottom: rem(64, 16);

    /* 64px / 16px*/
    @include laptop {
      margin-bottom: rem(128, 16);
      /* 128px / 16px */
    }
  }
}
// Colors
$dark-grey:   #11161E;
$medium-grey: #6A7180;
$light-grey:  #eee;
$blue:        #2266A6;
$white:       #fff;
$error:       red;

// Typography
$serif:        'Merriweather', serif;
$sans-serif:   'Roboto', sans-serif;

// Transition
$trans-fast:   300ms;
$trans-slow:   600ms;

// Responsive 
$tablet:       48em;
$laptop:       64em;
$desktop:      120em;

/************************************************************************** 
/ GOOGLE FONTS
**************************************************************************/
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700");
/*************************************************
* GLOBAL
*************************************************/
/* 
Root element 
************************/
html {
  font-size: 100%;
  line-height: 1.6875; }

/* 
Max-width for images
************************/
img {
  max-width: 100%;
  height: auto; }

/* 
Box sizing property
************************/
* {
  box-sizing: border-box; }

/* 
Paragraph reset
************************/
p {
  margin: 0;
  padding: 0; }

/* 
Default color for all links
************************/
a {
  color: #11161E; }

/* 
Prevent body from scrolling when main nav is opened
************************/
.no__scroll {
  overflow: hidden;
  position: fixed; }

/* 
Line (for decoration purposes)
************************/
.ruler > .line {
  display: inline-block;
  height: 0.1875rem;
  /* 3px / 16px */
  width: 3.125rem;
  /* 50px / 16px */
  background-color: #2266A6; }

.ruler > .square {
  display: inline-block;
  height: 0.1875rem;
  /* 3px / 16px */
  width: 0.1875rem;
  /* 3px / 16px */
  background-color: #2266A6; }

/* 
Clearfix
************************/
.clearfix {
  content: '';
  clear: both;
  display: table; }

/* 
Hidden / Visible classes
************************/
/*************************************************
* LAYOUT
*************************************************/
/* 
Layout 
************************/
.container {
  width: 82%;
  margin: 0 auto; }
  @media (min-width: 48em) {
    .container--outer {
      width: 90%;
      max-width: 90%;
      margin: 0 auto; }
    .container--inner {
      max-width: 43.75rem; } }
  @media (min-width: 64em) {
    .container--outer {
      max-width: 73.75rem; }
    .container--inner {
      max-width: 53.125rem; } }

.section {
  /* Line divider (decoration purposes) */
  /* Multi-column layout */
  /* Flex container */ }
  .section--overflow {
    overflow: hidden; }
  .section__header {
    background-color: #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 6.25rem;
    /* 100px / 16px */ }
    @media (min-width: 120em) {
      .section__header {
        height: 7.8125rem; } }
  .section__divider {
    background-color: #eee;
    height: 0.0625rem;
    /* 1px / 16px */
    width: 100%; }
  @media (min-width: 48em) {
    .section__column {
      -webkit-columns: 1;
      columns: 1; } }
  @media (min-width: 64em) {
    .section__column {
      -webkit-columns: 2;
      columns: 2;
      -webkit-column-gap: 2em;
      column-gap: 2em; } }
  @media (min-width: 48em) {
    .section--flex {
      display: flex;
      margin-left: auto;
      margin-right: auto; }
      .section--flex > .section__divider {
        display: none; }
      .section--flex > .project {
        width: 50%; }
        .section--flex > .project:first-child {
          margin-right: 1.5625rem;
          /* 25px / 16px */ } }
  .section__iframe {
    display: block;
    margin: 0 auto;
    height: 18.75rem;
    /* 300px / 16px */
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%; }
    @media (min-width: 48em) {
      .section__iframe {
        height: 31.25rem;
        /* 500px / 16px */ } }
  .section__logo {
    height: 2rem;
    width: 2rem; }

/* 
Margins 
************************/
@media (min-width: 48em) {
  .margin__r-reset {
    margin-right: 0; } }

@media (min-width: 48em) {
  .margin__l-reset {
    margin-left: 0 !important; } }

.margin__b-extra-small {
  margin-bottom: 0.75rem;
  /* 12px / 16px*/ }
  @media (min-width: 64em) {
    .margin__b-extra-small {
      margin-bottom: 1.25rem; } }

.margin__b-small {
  margin-bottom: 1.25rem;
  /* 20px / 16px*/ }
  @media (min-width: 64em) {
    .margin__b-small {
      margin-bottom: 2rem; } }

.margin__b-normal {
  margin-bottom: 2rem;
  /* 32px / 16px*/ }
  @media (min-width: 64em) {
    .margin__b-normal {
      margin-bottom: 3rem; } }

.margin__b-large {
  margin-bottom: 3rem;
  /* 48px / 16px*/ }
  @media (min-width: 64em) {
    .margin__b-large {
      margin-bottom: 6rem;
      /* 96px / 16px */ } }

.margin__b-extra-large {
  margin-bottom: 4rem;
  /* 64px / 16px*/ }
  @media (min-width: 64em) {
    .margin__b-extra-large {
      margin-bottom: 8rem;
      /* 128px / 16px */ } }

.section__title, .section__subtitle {
  font-family: "Merriweather", serif;
  font-size: 0.75rem;
  /* 12px / 16px */
  text-transform: uppercase; }

.section__title {
  color: #2266A6;
  font-weight: 700;
  position: relative; }
  @media (min-width: 48em) {
    .section__title {
      font-size: 1rem;
      /* 16px / 16px */ } }
  @media (min-width: 120em) {
    .section__title {
      font-size: 1.25rem;
      /* 20px / 16px */ } }
  .section__title-number {
    position: absolute;
    font-size: 3rem;
    z-index: -1;
    color: #eee;
    top: -1.875rem;
    /* 30px / 16px */ }
  .section__title--alternative {
    color: #fff;
    font-weight: 300; }

.section__subtitle {
  color: #6A7180;
  font-weight: 400;
  margin-bottom: 0rem;
  /* 0px / 16px */ }
  @media (min-width: 48em) {
    .section__subtitle {
      font-size: 0.8125rem;
      /* 13px / 16px */ } }
  @media (min-width: 120em) {
    .section__subtitle {
      font-size: 1rem;
      /* 16px / 16px */ } }

.section__paragraph {
  color: #11161E;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  /* 16px / 16px */ }
  .section__paragraph__end {
    background-color: #2266A6;
    display: inline-block;
    height: 0.75rem;
    /* 12px / 16px */
    margin-left: 5px;
    width: 0.75rem;
    /* 12px / 16px */ }
  @media (min-width: 48em) {
    .section__paragraph {
      font-size: 1.25rem;
      /* 20px / 16px */
      font-weight: 300;
      line-height: 1.85; } }
  @media (min-width: 120em) {
    .section__paragraph {
      font-size: 1.5rem;
      /* 24px / 16px */ } }
  @media (min-width: 64em) {
    .section__paragraph--big {
      font-size: 1.5rem;
      /* 24px / 16px */ } }
  @media (min-width: 120em) {
    .section__paragraph--big {
      font-size: 1.75rem;
      /* 24px / 16px */ } }
  @media (min-width: 64em) {
    .section__paragraph--small {
      font-size: 1rem;
      /* 16px / 16px */ } }
  .section__paragraph--alternative {
    color: #fff; }
  .section__paragraph--cursive {
    font-style: italic; }

.section__small {
  color: #6A7180; }
  @media (min-width: 120em) {
    .section__small {
      font-size: 1rem; } }

/*************************************************
* NAVIGATION
*************************************************/
/* 
nav - base styles 
************************/
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  /* 
  nav - logo
  ************************/
  /* 
  nav - logo
  ************************/ }
  @media (min-width: 64em) {
    .nav {
      padding: 2rem 0; } }
  .nav__logo {
    text-decoration: none;
    color: #6A7180; }
    .nav__logo--last-name {
      color: #11161E;
      font-weight: 700; }
    .nav__logo:hover {
      color: #11161E;
      font-weight: 700;
      transition: color 300ms; }
      .nav__logo:hover .nav__logo--last-name {
        color: #6A7180;
        font-weight: 400;
        transition: color 300ms; }
    @media (min-width: 48em) {
      .nav__logo {
        font-size: 1.25rem;
        /* 20px / 16px */ } }
    @media (min-width: 120em) {
      .nav__logo {
        font-size: 1.75rem;
        /* 28px / 16px */ } }
  .nav__menu__overlay {
    background-color: #fff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("/media/img/alejandro-loguercio/alejandro-loguercio-violin-mobile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 300ms, z-index 300ms;
    z-index: -1;
    overflow: hidden; }
    @media (min-width: 48em) {
      .nav__menu__overlay {
        background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url("/media/img/alejandro-loguercio/alejandro-loguercio-violin.jpeg"); } }
    @media (min-width: 64em) {
      .nav__menu__overlay {
        all: unset; } }

.list {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .list__item {
    padding-bottom: 0.5rem;
    /* 8px / 16px */ }

.footer {
  position: relative; }
  @media (min-width: 48em) {
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; } }
  @media (min-width: 48em) {
    .footer__sitemap, .footer__social {
      margin-bottom: 0; } }
  @media (min-width: 48em) {
    .footer__copyright {
      text-align: right; }
      .footer__copyright .btn {
        position: absolute;
        bottom: 0;
        right: 0; } }

/*************************************************
* LINKS
*************************************************/
/* 
Link - base styles 
************************/
.link {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  /* 16px / 16px */
  text-decoration: none;
  background-image: linear-gradient(to right, #11161E, #11161E);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 0.125rem;
  /* 2px / 16px */
  transition: background-size 300ms ease;
  padding-bottom: 0.25rem;
  /* 4px / 16px */
  letter-spacing: 1px;
  /* Skip to content link 
  ************************/
  /* Open/Close menu links
  ************************/
  /* Menu links
  ************************/
  /* 
  Active/Not-active class for navigation links 
  ************************/
  /* Footer links */ }
  @media (min-width: 48em) {
    .link {
      font-size: 1.25rem;
      /* 20px / 16px */ } }
  @media (min-width: 120em) {
    .link {
      font-size: 1.5rem;
      /* 24px / 16px */ } }
  .link:visited {
    color: #11161E; }
  .link:hover, .link:active {
    color: #2266A6;
    background-image: linear-gradient(to right, #2266A6, #2266A6);
    background-size: 20% 0.125rem;
    /* 2px / 16px */
    transition: color 600ms, background-size 600ms, background-image 600ms; }
  .link:focus {
    outline-color: #2266A6;
    outline-style: dotted;
    outline-offset: 0.5rem; }
  .link--skip {
    position: absolute;
    top: 0;
    right: 100%; }
    .link--skip:focus {
      right: auto; }
  .link--skip--top {
    position: absolute;
    top: -1.875rem;
    /* 30px / 16px */
    right: 1000%; }
    .link--skip--top:focus {
      right: auto; }
  .link--openBtn > svg, .link--closeBtn > svg {
    vertical-align: middle; }
  .link--openBtn:hover .link__icon, .link--openBtn:active .link__icon, .link--closeBtn:hover .link__icon, .link--closeBtn:active .link__icon {
    stroke: #2266A6;
    stroke-width: 2; }
  .link--openBtn::after, .link--closeBtn::after {
    display: none; }
  @media (min-width: 64em) {
    .link--openBtn, .link--closeBtn {
      display: none; } }
  .link--menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2.5rem;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    transform: translateY(40%);
    opacity: 0; }
    @media (min-width: 64em) {
      .link--menu {
        all: unset; } }
    .link--menu a {
      width: 100%;
      text-align: center;
      text-decoration: none;
      font-weight: 700;
      font-size: 1.5rem; }
      @media (min-width: 64em) {
        .link--menu a {
          font-size: 1rem;
          font-weight: 400;
          margin-left: 2rem;
          font-family: "Roboto", sans-serif; } }
      @media (min-width: 120em) {
        .link--menu a {
          font-size: 1.25rem;
          /* 20px / 16px */ } }
    .link--menu:first-child {
      text-align: right;
      margin-right: 2.5rem; }
      .link--menu:first-child a {
        font-weight: 400;
        text-transform: none;
        width: auto;
        font-size: 1rem; }
    .link--menu:last-child {
      text-align: right; }
      .link--menu:last-child a {
        font-size: 1rem;
        width: auto; }
        @media (min-width: 120em) {
          .link--menu:last-child a {
            font-size: 1.25rem;
            /* 20px / 16px */ } }
  .link--active {
    font-weight: 700; }
  .link--not-active {
    color: #6A7180;
    font-weight: 400; }
  .link--alternative {
    color: #6A7180;
    text-decoration: none; }
    .link--alternative:visited {
      color: #6A7180; }
    .link--alternative:hover, .link--alternative:active {
      color: #2266A6;
      text-decoration: underline;
      transition: color 600ms, background-size 600ms, background-image 600ms; }
    .link--alternative:focus {
      outline-color: #2266A6;
      outline-style: dotted;
      outline-offset: 0.5rem; }
    @media (min-width: 120em) {
      .link--alternative {
        font-size: 1.125rem; } }
    .link--alternative--white {
      color: #fff;
      background-image: linear-gradient(to right, #fff, #fff); }
  .link--back span, .link--next span {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    padding-top: 0.75em; }
  .link--next {
    text-align: right; }
  .link__btn__group {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .link--logo:hover, .link--logo:active {
    opacity: 0.5;
    transition: opacity 600ms; }
  .link--logo:focus {
    outline-color: #2266A6;
    outline-style: dotted;
    outline-offset: 0.5rem; }

.btn {
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0.75rem 0;
  text-align: center;
  text-decoration: none;
  width: 11.25rem;
  /* 180px / 16px*/ }
  .btn--primary {
    border: 1px solid #2266A6;
    color: #2266A6;
    background: linear-gradient(to right, #2266A6 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background 300ms ease-out, color 300ms ease-out; }
    .btn--primary:hover, .btn--primary:active, .btn--primary:focus {
      background-position: left bottom;
      color: #fff; }
    @media (min-width: 120em) {
      .btn--primary {
        font-size: 1.25rem;
        /* 20px , 16px */
        padding: 1.25rem 0;
        width: 15.625rem;
        /* 250px , 16px */ } }
  .btn--small {
    width: 4rem;
    /* 64px / 16px*/ }
    @media (min-width: 120em) {
      .btn--small {
        width: 6rem;
        /* 96px / 16px*/ } }

.section__image {
  display: block; }
  @media (min-width: 48em) {
    .section__image {
      margin-left: auto;
      margin-right: auto; } }
  .section__image--project {
    height: 15.625rem;
    /* 250px / 16px */
    width: 100%;
    object-fit: cover;
    object-position: top; }
    @media (min-width: 48em) {
      .section__image--project {
        height: 21.875rem;
        /* 350px / 16px */ } }
    .section__image--project--centered {
      object-position: center; }
  .section__image--banner {
    background-color: #eee;
    background-size: cover;
    background-position: center;
    height: 31.25rem;
    /* 500px / 16px */ }
    .section__image--banner--float {
      height: 21.875rem; }
    .section__image--banner--bio {
      background-image: url("/media/img/alejandro-loguercio/alejandro-loguercio-white-shirt.jpeg"); }
    .section__image--banner--crosmopolitans {
      background-image: url("/media/img/crosmopolitans/crosmopolitans-music.jpg"); }
    .section__image--banner--acrosTrio {
      background-image: url("/media/img/acros-trio/acros-trio-red.jpg");
      background-position: top; }
    .section__image--banner--garufa {
      background-image: url("/media/img/garufa/garufa-members-holding-instruments.jpeg"); }
    .section__image--banner--maxSteiner {
      background-image: url("/media/img/max-steiner/max_steiner_2.jpg"); }
    .section__image--banner--collaboration {
      background-image: url("/media/img/collaboration/collaboration-artists.jpg"); }
    .section__image--banner--contact {
      background-image: url("/media/img/alejandro-loguercio/alejandro-loguercio-with-violin.jpeg"); }
    @media (min-width: 48em) {
      .section__image--banner {
        background-position: top;
        height: 34.375rem;
        /* 550px / 16px */ }
        .section__image--banner--bio {
          background-image: url("/media/img/alejandro-loguercio/alejandro-loguercio-smiling-camera.jpeg"); }
        .section__image--banner--contact {
          background-image: url("/media/img/alejandro-loguercio/alejandro-loguercio-violin-forehand-smiling.jpeg"); }
        .section__image--banner--float {
          float: left;
          height: 28.125rem;
          width: 40%;
          margin-right: 2rem; } }

.press__image {
  display: none; }
  @media (min-width: 48em) {
    .press__image {
      display: block;
      background-image: url(/media/img/alejandro-loguercio/alejandro-loguercio-sitting-smiling.jpeg);
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      height: 22.1875rem;
      /* 355px / 16px */
      width: 100%; } }
  @media (min-width: 64em) {
    .press__image {
      background-position: top;
      height: 40.625rem;
      /* 650px / 16px */ } }

.audio__img {
  height: 15.625rem;
  /* 250px / 16px */
  background-color: #6A7180;
  background-image: url(/media/img/alejandro-loguercio/alejandro-loguercio-violin-forehand-smiling.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: -11%;
  margin-right: -11%;
  width: 100vw; }
  @media (min-width: 48em) {
    .audio__img {
      height: 21.875rem;
      /* 350px / 16px */
      margin: auto;
      width: auto; } }
  @media (min-width: 64em) {
    .audio__img {
      background-position: center;
      float: left;
      height: 25rem;
      /* 400px / 16px */
      width: 25rem;
      /* 400px / 16px */
      margin-right: 2rem;
      /* 32px / 16px */ }
      .audio__img::after {
        content: "";
        clear: both;
        display: table; } }
  @media (min-width: 120em) {
    .audio__img {
      height: 34.375rem;
      /* 550px / 16px */
      width: 34.375rem;
      /* 550px / 16px */ } }

/* 
AUDIO
******************/
.audio {
  position: relative; }
  .audio__control {
    cursor: pointer;
    position: absolute;
    top: 61%;
    right: 0%;
    border: none;
    background-color: #2266A6;
    text-align: center;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    background-repeat: no-repeat;
    background-position: center; }
    @media (min-width: 48em) {
      .audio__control {
        top: 68%; } }
    @media (min-width: 64em) {
      .audio__control {
        position: static; } }
  .audio__tracker {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 64em) {
      .audio__tracker {
        flex: 1;
        justify-content: unset; } }
  @media (min-width: 64em) {
    .audio__info {
      padding-top: 120px; } }
  @media (min-width: 64em) {
    .audio__player-controls {
      background-color: #eee;
      display: flex;
      padding: 1.25rem 2.5rem; } }

.play {
  background-image: url(/media/img/icons/bx-play.svg); }

.pause {
  background-image: url(/media/img/icons/bx-pause.svg); }

#currentTime {
  color: #2266A6; }
  @media (min-width: 64em) {
    #currentTime {
      margin-right: 1rem;
      font-size: 1rem;
      /* 16px / 16px */
      font-weight: 300; } }

#seekObjContainer {
  width: 80%;
  height: 5px; }
  @media (min-width: 64em) {
    #seekObjContainer {
      margin-right: 1rem;
      width: 100%; } }

#seekObj {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #eee;
  border: 5px; }
  @media (min-width: 64em) {
    #seekObj {
      background-color: #fff; } }

#percentage {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #2266A6; }

.form__label {
  display: block; }

.form__input {
  background-color: #eee;
  border: none;
  border: 2px solid #eee;
  border-radius: 4px;
  padding: 1em;
  width: 100%; }
  .form__input:hover,
  .form__input:active .form__input:focus {
    outline: none;
    border: 2px dotted #2266A6; }
  @media (min-width: 48em) {
    .form__input {
      font-size: 1rem; } }

.form__textarea {
  height: 20rem; }

.error {
  color: red;
  margin-top: 0.5em !important;
  margin-bottom: 1em !important; }

/*************************************************
* TRANSITIONS
*************************************************/
/*
Transitions 
************************/
/* Opacity applied to main menu transition  */
.opacity {
  z-index: 3 !important;
  opacity: 1 !important;
  transition: opacity 300ms !important; }

/* Transition effects for navigation links */
.move__in {
  transform: translateY(0%) !important;
  transition: all 1s;
  opacity: 1 !important; }
  .move__in--1 {
    transition-delay: 300ms; }
  .move__in--2 {
    transition-delay: 600ms; }
  .move__in--3 {
    transition-delay: 900ms; }
  .move__in--4 {
    transition-delay: 1200ms; }
  .move__in--5 {
    transition-delay: 1500ms; }
  .move__in--6 {
    transition-delay: 1800ms; }

/* 
JUMBOTRON
******************/
.jumbotron {
  position: relative; }

/* 
PROJECTS
******************/
@media (min-width: 48em) {
  .project--primary > .section__image--project:first-child {
    height: 34.375rem;
    /* 550px / 16px */ } }

@media (min-width: 48em) {
  .project--secondary > .container--outer {
    display: flex;
    flex-direction: column;
    height: 18.75rem;
    /* 300px / 16px */
    justify-content: space-between; } }

.project--outer {
  height: 31.25rem;
  /* 500px / 16px */
  overflow: hidden;
  text-decoration: none; }
  @media (min-width: 120em) {
    .project--outer {
      height: 36.25rem;
      /* 580px / 16px */ } }
  .project--outer--secondary {
    height: 26.5625rem;
    /* 425px / 16px */ }

.project--inner {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 600ms ease;
  background-color: #11161E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 64em) {
    .project--inner {
      background-size: 100%; }
      .project--inner:hover {
        background-size: 90%; }
        .project--inner:hover .project--inner__span {
          opacity: 1; } }
  .project--inner__span {
    display: block;
    transition: all 600ms ease; }
    @media (min-width: 64em) {
      .project--inner__span {
        opacity: 0; } }

.project--layer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.crosmopolitans {
  background-image: linear-gradient(rgba(17, 22, 30, 0.5), rgba(17, 22, 30, 0.5)), url("/media/img/crosmopolitans/crosmopolitans.jpg"); }

.acrosTrio {
  background-image: linear-gradient(rgba(17, 22, 30, 0.5), rgba(17, 22, 30, 0.5)), url("/media/img/acros-trio/acros-trio-b&w.jpg"); }

.garufa {
  background-image: linear-gradient(rgba(17, 22, 30, 0.5), rgba(17, 22, 30, 0.5)), url("/media/img/garufa/garufa.jpg"); }

/* 
PRESS
******************/
@media (min-width: 48em) {
  .press {
    display: flex;
    align-items: center; }
    .press .section__title {
      color: #2266A6; }
    .press .section__paragraph {
      color: #11161E; } }

.press__content {
  background-color: #11161E;
  background-image: linear-gradient(rgba(17, 22, 30, 0.5), rgba(17, 22, 30, 0.5)), url(/media/img/alejandro-loguercio/alejandro-loguercio-sitting-smiling.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 35rem;
  /* 560px / 16px */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin: 0 calc(-50vw + 50%);
  padding: 0 2.125rem;
  /* 34px / 16px */ }
  @media (min-width: 48em) {
    .press__content {
      background: none;
      border-left: 0.25rem solid #2266A6;
      height: auto;
      margin-left: 1rem;
      padding-left: 1rem;
      width: auto;
      margin-right: -1rem;
      padding-right: 0; } }

.subscribe {
  margin-bottom: 4rem; }
  @media (min-width: 64em) {
    .subscribe {
      margin-bottom: 0; } }
  @media (min-width: 64em) {
    .subscribe {
      display: flex;
      flex-direction: row; } }
  .subscribe__img {
    background-color: #eee;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 30vh;
    width: 100%;
    margin-bottom: 1.25rem;
    flex: 1;
    flex-grow: 2; }
    .subscribe__img--main {
      background-image: url("/media/img/crosmopolitans/crossmopolitans-subscribe-mobile-3.jpg"); }
      @media (orientation: landscape) {
        .subscribe__img--main {
          height: 60vh; } }
      @media (min-width: 64em) {
        .subscribe__img--main {
          background-image: url("/media/img/crosmopolitans/crossmopolitans-subscribe-low.jpg"); } }
    .subscribe__img--alternative {
      background-image: url("/media/img/crosmopolitans/crosmopolitans.jpg"); }
    @media (min-width: 48em) {
      .subscribe__img {
        height: 60vh;
        background-position: top; } }
    @media (min-width: 64em) {
      .subscribe__img {
        height: 100vh;
        max-height: 100vh;
        width: 100%;
        margin-bottom: 0; } }
  @media (min-width: 64em) {
    .subscribe__info {
      flex: 1; }
      .subscribe__info .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        max-height: 100vh; }
      .subscribe__info .margin__b-extra-small {
        margin-bottom: 1rem; } }

/*************************************************
* LINKS
*************************************************/

/* 
Link - base styles 
************************/
.link {
  font-family: $sans-serif;
  font-size: rem(16, 16);
  /* 16px / 16px */
  text-decoration: none;
  background-image: linear-gradient(to right, $dark-grey, $dark-grey);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% rem(2, 16);
  /* 2px / 16px */
  transition: background-size $trans-fast ease;
  padding-bottom: rem(4, 16);
  /* 4px / 16px */
  letter-spacing: 1px;

  @include tablet {
    font-size: rem(20, 16);
    /* 20px / 16px */
  }

  @include desktop {
    font-size: rem(24, 16);
    /* 24px / 16px */
  }

  &:visited {
    color: $dark-grey;
  }

  &:hover,
  &:active {
    color: $blue;
    background-image: linear-gradient(to right, $blue, $blue);
    background-size: 20% rem(2, 16);
    /* 2px / 16px */
    transition: color $trans-slow, background-size $trans-slow, background-image $trans-slow;

  }

  &:focus {
    outline-color: $blue;
    outline-style: dotted;
    outline-offset: 0.5rem;
  }

  /* Skip to content link 
  ************************/
  &--skip {
    position: absolute;
    top: 0;
    right: 100%;

    &:focus {
      right: auto;
    }
  }

  &--skip--top {
    position: absolute;
    top: -(rem(30, 16));
    /* 30px / 16px */
    right: 1000%;

    &:focus {
      right: auto;
    }
  }

  /* Open/Close menu links
  ************************/
  &--openBtn,
  &--closeBtn {

    &>svg {
      vertical-align: middle;
    }

    &:hover,
    &:active {
      .link__icon {
        stroke: $blue;
        stroke-width: 2;
      }
    }

    &::after {
      display: none;
    }

    @include laptop {
      display: none;
    }
  }

  /* Menu links
  ************************/

  &--menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2.5rem;
    font-family: $sans-serif;
    text-transform: uppercase;
    transform: translateY(40%);
    opacity: 0;

    @include laptop {
      all: unset;
    }

    & a {
      width: 100%;
      text-align: center;
      text-decoration: none;
      font-weight: 700;
      font-size: rem(24, 16);

      @include laptop {
        font-size: rem(16, 16);
        font-weight: 400;
        margin-left: rem(32, 16);
        font-family: $sans-serif;
      }

      @include desktop {
        font-size: rem(20, 16);
        /* 20px / 16px */
      }
    }

    &:first-child {
      text-align: right;
      margin-right: 2.5rem;

      & a {
        font-weight: 400;
        text-transform: none;
        width: auto;
        font-size: rem(16, 16);
      }
    }

    &:last-child {
      text-align: right;

      & a {
        font-size: rem(16, 16);
        width: auto;

        @include desktop {
          font-size: rem(20, 16);
          /* 20px / 16px */
        }
      }
    }

  }

  /* 
  Active/Not-active class for navigation links 
  ************************/
  &--active {
    font-weight: 700;
  }

  &--not-active {
    color: $medium-grey;
    font-weight: 400;
  }

  /* Footer links */
  &--alternative {
    color: $medium-grey;
    text-decoration: none;

    &:visited {
      color: $medium-grey;
    }

    &:hover,
    &:active {
      color: $blue;
      text-decoration: underline;
      transition: color $trans-slow, background-size $trans-slow, background-image $trans-slow;
    }

    &:focus {
      outline-color: $blue;
      outline-style: dotted;
      outline-offset: 0.5rem;
    }

    @include desktop {
      font-size: rem(18, 16);
    }

    &--white {
      color: $white;
      background-image: linear-gradient(to right, $white, $white);
    }
  }

  &--back,
  &--next {
    span {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      padding-top: 0.75em;
    }
  }

  &--next {
    text-align: right;
  }

  &__btn__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--logo {
    &:hover,
    &:active {
      opacity: 0.5;
      transition: opacity $trans-slow;
    }

    &:focus {
      outline-color: $blue;
      outline-style: dotted;
      outline-offset: 0.5rem;
    }
  }
}